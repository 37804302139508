<template>
  <v-theme-provider dark>
    <base-section
      id="info-alt"
    >
      <v-container>
        <v-row>
          <v-col
            cols="12"
            md="5"
          >
            <base-info to="/about" />

            <base-business-contact />
          </v-col>

          <v-col
            class="text-center hidden-sm-and-down"
            cols="2"
          >
            <v-divider vertical />
          </v-col>

          <v-col
            cols="12"
            md="5"
          >
            <base-contact-form
              :subtitle="$t('sections.info.subtitle')"
              :title="$t('components.basecontactform.title')"
            />
          </v-col>
        </v-row>
      </v-container>
    </base-section>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'SectionBusinessInfo',
  }
</script>
